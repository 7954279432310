import axios from "@/plugins/axios"
import qs from "qs"

export const getAllBrands = async (page = 1, pageSize = 500) => {
  const query = qs.stringify({
    pagination: {
      page,
      pageSize,
    },
    populate: "*",
    products: {
      populate: "*",
      image: "*",
    },
    localizations: {
      populate: "*",
    },
    sort: ["order:asc"],
  })
  const response = await axios.get(`/brands?${query}`)
  return response?.data
}

export const getBrandById = async (id) => {
  const query = qs.stringify({
    populate: {
      products: {
        // populate: "*",
        populate: {
          // populate: ,
          fields: ["name", "strength", "image"],
          image: "*",
        },
      },
      localizations: {
        populate: "*",
      },
      image: "*",
    },
  })
  const response = await axios.get(`/brands/${id}?${query}`)

  return response?.data
}
