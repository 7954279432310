<template>
  <div
    ref="brandProducts"
    class="full-brand__products"
    v-if="products.length"
  >
    <div class="full-brand__products-header full-brand__section-header">
      <h2 class="full-brand__products-title full-brand__title">{{ $t("MoreAboutProducts") }}</h2>
      <div class="page__top-arrows discount-catalog__arrows">
        <button
          class="discount-catalog__prev"
          @click="pagination.page--"
          :class="{ 'is-first': pagination.page === 1 }"
          :disabled="pagination.page === 1"
        >
          <img
            src="@/assets/img/icons/prev-arrow-white.svg"
            alt=""
          />
        </button>
        <button
          class="discount-catalog__next"
          @click="pagination.page++"
          :class="{ 'is-last': pagination.page === pagination.pageCount }"
          :disabled="pagination.page === pagination.pageCount"
        >
          <img
            src="@/assets/img/icons/next-arrow-white.svg"
            alt=""
          />
        </button>
      </div>
    </div>

    <div
      v-if="products.length"
      class="full-brand__products-items"
    >
      <product-card
        v-for="item in products"
        :key="item.id"
        :item="item"
      />
    </div>

    <div class="full-brand__products-pagination">
      <button
        class="first__page"
        :class="{ 'is-first': pagination.page === 1 }"
        @click="pagination.page = 1"
        :disabled="pagination.page === 1"
      >
        <svg
          width="15"
          height="18"
          viewBox="0 0 15 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.11332 15.4677C0.866961 15.7996 0.866961 16.2537 1.11332 16.5856C1.48825 17.0907 2.24436 17.0907 2.61929 16.5856L7.91177 9.45561C8.20076 9.06629 8.20076 8.53366 7.91177 8.14434L2.61929 1.01438C2.24436 0.509281 1.48825 0.509281 1.11332 1.01438C0.866961 1.34628 0.866961 1.80034 1.11332 2.13224L5.57604 8.14434C5.86502 8.53366 5.86503 9.06629 5.57604 9.45561L1.11332 15.4677Z"
            fill="currentColor"
          />
          <path
            d="M7.11332 15.4677C6.86696 15.7996 6.86696 16.2537 7.11332 16.5856C7.48825 17.0907 8.24436 17.0907 8.61929 16.5856L13.9118 9.45561C14.2008 9.06629 14.2008 8.53366 13.9118 8.14434L8.61929 1.01438C8.24436 0.509281 7.48825 0.509281 7.11332 1.01438C6.86696 1.34628 6.86696 1.80034 7.11332 2.13224L11.576 8.14434C11.865 8.53366 11.865 9.06629 11.576 9.45561L7.11332 15.4677Z"
            fill="currentColor"
          />
        </svg>
      </button>
      <button
        class="prev"
        @click="pagination.page--"
        :class="{ 'is-first': pagination.page === 1 }"
        :disabled="pagination.page === 1"
      >
        <svg
          width="9"
          height="17"
          viewBox="0 0 9 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.11332 15.4678C0.866961 15.7997 0.866961 16.2538 1.11332 16.5857C1.48825 17.0908 2.24436 17.0908 2.61929 16.5857L7.91177 9.45573C8.20076 9.06641 8.20076 8.53379 7.91177 8.14447L2.61929 1.0145C2.24436 0.509403 1.48825 0.509403 1.11332 1.0145C0.866961 1.3464 0.866961 1.80046 1.11332 2.13236L5.57604 8.14446C5.86502 8.53379 5.86503 9.06641 5.57604 9.45573L1.11332 15.4678Z"
            fill="currentColor"
          />
        </svg>
      </button>

      <div class="digits">
        <p
          v-for="(page, index) in pagination.pageCount"
          :key="index"
          :class="{ active: page === pagination.page }"
          @click="pagination.page = page"
        >
          {{ page }}
        </p>
      </div>

      <button
        class="next"
        @click="pagination.page++"
        :class="{ 'is-last': pagination.page === pagination.pageCount }"
        :disabled="pagination.page === pagination.pageCount"
      >
        <svg
          width="9"
          height="17"
          viewBox="0 0 9 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.11332 15.4678C0.866961 15.7997 0.866961 16.2538 1.11332 16.5857C1.48825 17.0908 2.24436 17.0908 2.61929 16.5857L7.91177 9.45573C8.20076 9.06641 8.20076 8.53379 7.91177 8.14447L2.61929 1.0145C2.24436 0.509403 1.48825 0.509403 1.11332 1.0145C0.866961 1.3464 0.866961 1.80046 1.11332 2.13236L5.57604 8.14446C5.86502 8.53379 5.86503 9.06641 5.57604 9.45573L1.11332 15.4678Z"
            fill="currentColor"
          />
        </svg>
      </button>
      <button
        class="last__page"
        :class="{ 'is-last': pagination.page === pagination.pageCount }"
        @click="pagination.page = pagination.pageCount"
        :disabled="pagination.page === pagination.pageCount"
      >
        <svg
          width="15"
          height="18"
          viewBox="0 0 15 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.11332 15.4677C0.866961 15.7996 0.866961 16.2537 1.11332 16.5856C1.48825 17.0907 2.24436 17.0907 2.61929 16.5856L7.91177 9.45561C8.20076 9.06629 8.20076 8.53366 7.91177 8.14434L2.61929 1.01438C2.24436 0.509281 1.48825 0.509281 1.11332 1.01438C0.866961 1.34628 0.866961 1.80034 1.11332 2.13224L5.57604 8.14434C5.86502 8.53366 5.86503 9.06629 5.57604 9.45561L1.11332 15.4677Z"
            fill="currentColor"
          />
          <path
            d="M7.11332 15.4677C6.86696 15.7996 6.86696 16.2537 7.11332 16.5856C7.48825 17.0907 8.24436 17.0907 8.61929 16.5856L13.9118 9.45561C14.2008 9.06629 14.2008 8.53366 13.9118 8.14434L8.61929 1.01438C8.24436 0.509281 7.48825 0.509281 7.11332 1.01438C6.86696 1.34628 6.86696 1.80034 7.11332 2.13224L11.576 8.14434C11.865 8.53366 11.865 9.06629 11.576 9.45561L7.11332 15.4677Z"
            fill="currentColor"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { getBrandProducts } from "@/api/products"

export default {
  name: "AppBrandProducts",

  components: {
    ProductCard: () => import("@/components/products/ProductCard.vue"),
  },

  props: {
    brand: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      products: [],
      pagination: {
        page: 1,
        pageSize: 4,
        isLastPage: false,
        pageCount: null,
      },
    }
  },

  watch: {
    "pagination.page": {
      handler() {
        getBrandProducts(this.pagination.page, this.pagination.pageSize, this.brand).then((res) => {
          this.products = res.data
          if (this.$isMobile()) {
            this.$refs.brandProducts.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
        })
      },
    },
  },
  created() {
    getBrandProducts(this.pagination.page, this.pagination.pageSize, this.brand).then((res) => {
      this.products = res.data
      this.pagination.pageCount = res.meta.pagination.pageCount
    })
  },

  computed: {},
}
</script>

<style
  lang="scss"
  scoped
>
.discount-catalog__prev {
  &.is-first {
    opacity: 0.5;
    cursor: default;
  }
}

.discount-catalog__next {
  &.is-last {
    opacity: 0.5;
    cursor: default;
  }
}
</style>
