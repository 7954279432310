<template>
  <div class="full-brand">
    <div class="container">
      <div class="full-brand__nav">
        <locale-router-link
          to=""
          class="crumbs-link main-page-link"
        >
          {{ $t("home") }}
        </locale-router-link>
        <locale-router-link
          to="/brands"
          class="crumbs-link"
        >
          {{ $t("brands.title") }}
        </locale-router-link>
        <a class="crumbs-link recipes-link"> {{ brandData.attributes.header }}</a>
      </div>

      <div class="full-brand__main">
        <h1 class="full-brand__title">{{ brandData.attributes.header }}</h1>
        <div class="full-brand__image">
          <img
            :src="getImageUrl"
            alt=""
          />
        </div>

        <vue-markdown
          class="full-brand__description full-brand__text"
          :source="brandData.attributes.text"
        >
          {{ brandData.attributes.text }}
        </vue-markdown>
      </div>
      <app-brand-products :brand="getBrandTitle(brandData)" />
      <!-- <brand-slider /> -->
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
import { getBrandById } from "@/api/brands"
import AppBrandProducts from "@/components/brands/AppBrandProducts.vue"
// import BrandSlider from "@/components/brands/BrandSlider.vue"

export default {
  name: "FullBrands",

  components: {
    AppBrandProducts,
    // BrandSlider,
    VueMarkdown
  },

  data() {
    return {
      brandData: {},
    }
  },

  computed: {
    getImageUrl() {
      if (this.brandData.attributes?.image?.data) {
        return this.$helpers.getAbsolutePath(
          this.brandData.attributes?.image?.data?.attributes?.url,
        )
      }
      return require("@/" + "assets/img/icons/placeholder.svg")
    },
  },

  mounted() {
    this.loadBrandData()
  },

  methods: {
    async loadBrandData() {
      const response = await getBrandById(this.$attrs?.id)
      this.brandData = response.data
    },

    getBrandTitle(item) {
      return item?.attributes?.header
    },
  },
}
</script>

<style
  lang="scss"
  scoped
></style>
